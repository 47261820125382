.str-chat__channel {
  width: 100%;
  height: 840px;
}

.center {
  width: 100%;
  height: 840px;
  display:flex;
  justify-content: center;
  align-items: center;
}